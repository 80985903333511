<template>
  <layout class="home">
    <!-- 头部 -->
    <!-- <div class="home-header">
      <ChainHeader></ChainHeader>
    </div>-->
    <!-- 内容 -->
    <div class="main">
        <router-view />
    </div>
  </layout>
</template>

<script>
import layout from '@/components/layout'

export default {
  name: 'Home',
  components: {
    layout
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
</style>
