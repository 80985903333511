import Vue from 'vue'
// element-ui
import {
  Button,
  Input,
  Form,
  FormItem,
  Breadcrumb,
  BreadcrumbItem,
  Upload,
  Message,
  Loading,
  Dialog,
  Table,
  TableColumn,
  Pagination,
  Tabs,
  TabPane,
  Empty

} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// use
// Vue.use(ElementUI)

Vue.use(Button)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Upload)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Empty)
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message
// Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$alert = MessageBox.alert;
// Vue.prototype.$confirm = MessageBox.confirm;
// Vue.prototype.$prompt = MessageBox.prompt;
// Vue.prototype.$notify = Notification;

// 混合源组件
mixinComponent({
  ElButton: require('./button'),
  ElInput: require('./input'),
  ElDialog: require('./dialog'),
  ElBreadcrumb: require('./breadcrumb'),
  ElTable: require('./table'),
  ElPagination: require('./pagination')
})

export default function mixinComponent(mixinObj) {
  for (var key in mixinObj) {
    Vue.component(key).mixin(mixinObj[key])
  }
}
