<template>
  <div class="addressError">
    <img class="img-bg1" src="https://static-10006892.file.myqcloud.com/points-mall/images/404.png" alt />
  </div>
</template>

<script>
export default {
  name: 'addressError',
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>

<style scoped lang="less">
.addressError {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
