import Vue from 'vue'
import Vuex from 'vuex'
import {
  browserOverviewData
} from '@/api/services/api'
import createPersistedState from 'vuex-persistedstate'
import homeWrap from './modules/homeWrap'

Vue.use(Vuex)

const typeOpt = {
  /**
   *  1-区块高度 blockInfo 2-块哈希 blockInfo 3-用户地址 address
   *  4-积分名称 integralDetails 5-交易哈希 tradingInfo 6-合约地址 contractInfo
   *  7-首页
   */
  1: 'blockInfo',
  2: 'blockInfo',
  3: 'address',
  4: 'integralDetails',
  5: 'tradingInfo',
  6: 'contractInfo',
  7: 'integralBrowser'
}

export default new Vuex.Store({
  state: {
    returnType: '', // 1-区块高度 2-块哈希 3-用户地址 4-积分名称 5-交易哈希 6-合约地址
    isloading: false, // 页面loding
    otherMap: {}, // 返回除 rows total以外的数据，与returnType 的值有关
    listData: [], // 列表信息
    totals: 0, // 总条数
    routeName: 'integralBrowser', // 默认首页name
    inParam: { // 保存请求参数，用于刷新页面回写
      page: 1,
      limit: 10,
      query: ''
    }
  },
  mutations: {
    setFormData(state, data) {
      state.listData = data.rows
      state.otherMap = data.otherMap
      state.routeName = typeOpt[data.returnType] || 'empty'
      state.totals = Number(data.total || 0)
      state.returnType = data.returnType
      state.inParam = data.inParam
    },
    setLoading(state, data) {
      state.isloading = data
    }
  },
  actions: {
    setFormData({ commit },
      data = {
        page: 1,
        limit: 10,
        query: ''
      }
    ) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        browserOverviewData(data)
          .then(res => {
            res.inParam = data
            /**
             * 时间2020-09-11 14:56:40 =》 2020/09/11 14:56:40
            */
            if (JSON.stringify(res.rows).indexOf('createDate') != -1) {
              res.rows.forEach(temp => {
                temp.createDate = temp.createDate.replace(/-/g, '/')
              })
            }
            res.otherMap.createDate = res.otherMap.createDate ? res.otherMap.createDate.replace(/-/g, '/') : ''
            commit('setFormData', res)
            commit('setLoading', false)
            resolve(res)
          })
          .catch(() => {
            commit('setFormData', {
              integralSum: 0,
              maxBlockNumber: 0,
              txNumber: 0,
              total: 0,
              rows: [],
              returnType: '',
              inParam: data
            })
            commit('setLoading', false)
          })
      })
    }
  },
  /**
   * VUEX 数据持久化，
   * 刷新页面数据仍然存在
  */
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  modules: {
    homeWrap: {
      namespaced: 'homeWrap',
      ...homeWrap
    }
  }
})
