<template>
  <div class="head-wrap" :class="{ 'search-head-wrap': isSearch }">
    <div class="head-main">
      <div class="main-left" @click="toHome">
        <img
          src="https://static-10006892.file.myqcloud.com/points-browser/img/ADF_logo_icon.png"
          alt=""
          class="logo-icon"
        />
        <span class="adfunds">ADFunds </span>
        <el-divider direction="vertical"></el-divider>
        <span class="browser"> {{ $t("header.title") }}</span>
      </div>
      <div class="main-right">
        <!-- <div class="international" >
        <span v-for="(value,key) in lang" :key="key">
          <span class="inter-item"  :class="{isActive:activeLang===key}" @click="handleLangChange(key)">{{value}}</span>
          <span class="inter-line" v-if="key!='EN'"></span>
        </span>
      </div> -->
        <div class="input-wrap">
          <el-input
            class="search-input"
            :class="{ 'seach-value': query.condition }"
            :value="query.condition"
            @input="inputChange"
            size="small"
            :placeholder="$t('header.placeholder')"
            @keyup.native.enter="handleSearch"
            clearable
          >
            <i slot="prefix" class="el-input__icon el-icon-search icon"></i>
          </el-input>
        </div>
        <div class="input-wrap-m" @click="toSearchDetail">
          <i class="el-input__icon el-icon-search"></i>
          搜索
        </div>
      </div>
    </div>
    <!-- 移动端搜索头部 -->
    <div class="search-head-moblie" v-if="isSearch">
      <div class="main-left" @click="toHome">
        <img
          src="https://static-10006892.file.myqcloud.com/points-browser/img/ADF_logo_icon.png"
          alt=""
          class="logo-icon"
        />
        <span class="adfunds">ADFunds </span>
        <el-divider direction="vertical"></el-divider>
        <span class="browser"> {{ $t("header.title") }}</span>
      </div>
      <div class="search-wrap">
        <i class="iconfont el-icon-arrow-left" @click="toHome"></i>
        <el-input
          class="search-input"
          :class="{ 'seach-value': query.condition }"
          :value="query.condition"
          @input="inputChange"
          size="small"
          ref="input"
          prefix-icon="el-icon-search"
          clearable
          @focus="handleFocus"
          @blur="handleBlur"
          :placeholder="$t('header.placeholder')"
        >
          <div slot="append" class="search-btn" @click="handleSearch">搜索</div>
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'
export default {
  props: {
    isSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headTitle: '',
      activeLang: 'zh-CN',
      lang: {
        'zh-CN': '中',
        'zh-TW': '繁',
        en: 'EN'
      },
      value: '',
      isPost: false
    }
  },
  computed: {
    ...mapState('homeWrap', {
      query: state => state.query
    })
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.input) {
        this.$refs.input.focus()
      }
    })
  },
  methods: {
    ...mapMutations('homeWrap', [
      'setQuery',
      'setIsFocus'
    ]),
    ...mapActions('homeWrap', [
      'queryData'
    ]),
    toHome() {
      this.setQuery({
        ...this.query,
        condition: ''
      })
      this.$router.push('homeData')
    },
    inputChange(val) {
      this.setQuery({
        ...this.query,
        condition: val
      })
    },
    async handleSearch(e) {
      if (this.isPost) return
      this.isPost = true
      if (this.$route.name !== 'searchDetail') {
        this.$router.push('searchDetail')
      } else {
        await this.queryData()
      }
      this.isPost = false
      // if (!this.query.condition) return
    },
    toSearchDetail() {
      this.$router.push('searchDetail')
    },
    handleLangChange(key) {
      this.activeLang = key
      this.$i18n.locale = key
    },
    handleBlur() {
      this.setIsFocus(false)
    },
    handleFocus() {
      console.log('focus')
      this.setIsFocus(true)
    }
  }
}
</script>

<style lang="less" scoped>
.head-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #0070ff;
  height: 80px;
  z-index: 9;
}
.head-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 1200px;
  height: 100%;
  color: #fff;
  .main-left {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .logo-icon {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
  .adfunds {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  .browser {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
  }
}
.main-right {
  display: flex;
  align-items: center;
  .inter-item {
    display: inline-block;
    width: 30px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    opacity: 0.5;
    cursor: pointer;
    &.isActive {
      opacity: 1;
    }
  }
  .inter-line {
    display: inline-block;
    width: 1px;
    height: 12px;
    background: #fff;
    opacity: 0.5;
  }
  .international {
    margin-right: 16px;
  }
}
.search-input {
  width: 384px;
  .icon {
    color: #80b7ff;
  }
  /deep/.el-input__inner:focus + .el-input__prefix .icon {
    color: #333;
  }
  /deep/ .el-input__inner {
    background: #1c80ff;
    border: 1px solid #80b7ff;
    color: #fff;
    border-radius: unset;
    &::placeholder {
      color: hsla(0, 0%, 100%, 1);
      color: inherit;
      opacity: 0.6;
    }
    &:focus {
      background: #ffffff;
      opacity: 1;
      color: #333;
    }
  }
  &.seach-value {
    /deep/.el-input__inner {
      background: #ffffff;
      opacity: 1;
      color: #333;
    }
  }
}

.input-wrap-m {
  display: none;
  padding-right: 8px;
  opacity: 0.5;
  border: 1px solid #ffffff;
  height: 24px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;

  .el-input__icon {
    line-height: 24px;
  }
}

.search-head-moblie {
  display: none;
}
@import url("./style.less");
</style>
