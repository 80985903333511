import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import addressError from '@/views/addressError/index.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      redirect: 'homeData',
      children: [
        {
          path: '/homeData',
          name: 'homeData',
          component: () => import('@/views/homeData')
        },
        {
          path: '/nftIdDetail',
          name: 'nftIdDetail',
          component: () => import('@/views/searchDetail'),
          meta: {
            breadList: [
              {
                name: '区块浏览器',
                path: '/homeData'
              },
              {
                name: '数据详情',
                path: ''
              }
            ]
          }
        },
        {
          path: '/blockDetail',
          name: 'blockDetail',
          component: () => import('@/views/blockDetail'),
          meta: {
            breadList: [
              {
                name: '区块浏览器',
                path: '/homeData'
              },
              {
                name: '区块详情',
                path: '/hashBlock'
              },
              {
                name: '数据详情',
                path: ''
              }
            ]
          }
        },
        {
          path: '/moreBlockData',
          name: 'moreBlockData',
          component: () => import('@/views/moreBlockData'),
          meta: {
            breadList: [
              {
                name: '区块浏览器',
                path: '/homeData'
              },
              {
                name: '更多区块数据',
                path: ''
              }
            ]
          }
        },
        {
          path: '/moreCreateData',
          name: 'moreCreateData',
          component: () => import('@/views/moreCreateData'),
          meta: {
            breadList: [
              {
                name: '区块浏览器',
                path: '/homeData'
              },
              {
                name: '查看更多区块数据',
                path: ''
              }
            ]
          }
        },
        {
          path: '/hashBlock',
          name: 'hashBlock',
          component: () => import('@/views/hashBlock'),
          meta: {
            breadList: [
              {
                name: '区块浏览器',
                path: '/homeData'
              },
              {
                name: '区块详情',
                path: ''
              }
            ]
          }
        }
      ]
    },
    {
      path: '/searchDetail',
      name: 'searchDetail',
      component: () => import('@/views/searchDetail'),
      meta: {
        breadList: [
          {
            name: '区块浏览器',
            path: '/homeData'
          },
          {
            name: '搜索详情',
            path: ''
          }
        ]
      }
    },
    {
      path: '*',
      name: 'addressError',
      component: addressError
    }
  ]
})
router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)
  next()
})
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})

export default router
