import request from '../request'
import urls from '@/config/urls'

// 首页分页条件查询 http://yapi-jx.idouzi.com/project/270/interface/api/30471
export function browserOverviewData(params) {
  return request({
    url: `${urls.usdt}/api/universalExchange/chainIntegralBrowser/backManage/browserOverviewData`,
    // params
    method: 'post',
    data: params
  })
}

// 联盟积分 > 积分种类  http://yapi-jx.idouzi.com/project/270/interface/api/31002
export function getChainIntegralList(params) {
  return request({
    url: `${urls.usdt}/api/universalExchange/integralInfo/backManage/getChainIntegralList`,
    method: 'post',
    data: params
  })
}
// 联盟积分 > 积分详情  http://yapi-jx.idouzi.com/project/270/interface/api/31020
export function getIntegralDetail(params) {
  return request({
    url: `${urls.usdt}/api/universalExchange/chainIntegralBrowser/backManage/getIntegralDetail`,
    method: 'post',
    data: params
  })
}
