import api from '@/api/services/browser'
export default {
  state: {
    query: {
      condition: '',
      page: 1,
      size: 10
    },
    result: {},
    isFocus: false
  },
  mutations: {
    setQuery(state, data) {
      state.query = data
    },
    setResult(state, data) {
      state.result = data
    },
    setIsFocus(state, data) {
      state.isFocus = data
    }
  },
  actions: {
    async queryData({ commit, state }, data) {
      let res = await api.selectByConditions({
        ...state.query,
        ...data
      })
      commit('setResult', res)
    }
  }
}
