export default {
  header: {
    title: '区块浏览器',
    placeholder: '输入地址/交易哈希值/NFT ID 进行搜索'
  },
  block: {
    yesterday: '昨日数据',
    total: '总数据',
    blockNum: '区块数量',
    dataNum: '数据量',
    tradeNum: '交易量'
  },
  table: {
    newBlockData: '最新区块数据',
    newCreateData: '最新生成数据',
    blockHeight: '区块高度',
    hashValue: '哈希值',
    linkTime: '上链时间',
    dataId: '数据ID',
    ads: '广告主',
    flows: '流量主',
    flowAdress: '流量主地址',
    addressCanJump: '流量主地址 是否可跳转',
    minAppName: '小程序名称(上链时名称)',
    createTime: '生成时间',
    tradeType: '交易类型',
    tradeTime: '交易时间',
    nftID: 'NFT ID',
    name: '名称'
  },
  detail: {
    dataDetail: '数据详情',
    nftDetail: 'NFT详情',
    dataType: '数据类型',
    tradeAdress: '交易地址转移',
    cardName: '会员卡名称',
    benifit: '会员权益',
    cardIntro: '会员卡介绍',
    publish: '发行方',
    publishAdress: '发行方地址',
    cost: '价值',
    content: '内容',
    linkTime: '上链时间',
    hashValue: '哈希值',
    blockHeight: '区块高度(NFT)'
  }
}
