import request from '../request'
import config from '@/config/urls'

export default {
  // 点击区块哈希查询详情
  getByBlockHash(data) {
    return request({
      url: '/api/wallet/chainBrowser/getByBlockHash',
      method: 'POST',
      data
    })
  },
  getByTransferHash(data) {
    return request({
      url: '/api/wallet/chainBrowser/getByTxId',
      method: 'POST',
      data
    })
  },
  // 获取统计数据
  getStaticData(params) {
    return request({
      url: '/api/wallet/chainBrowser/getStaticData',
      method: 'GET',
      params
    })
  },
  // 获取区块列表
  getBlockList(data) {
    return request({
      url: '/api/wallet/chainBrowser/getBlockList',
      method: 'POST',
      data
    })
  },
  // 搜索
  selectByConditions(data) {
    return request({
      url: '/api/wallet/chainBrowser/selectByConditions',
      method: 'POST',
      data
    })
  },
  // 获取区块列表
  getAdData(data) {
    return request({
      url: '/api/wallet/chainBrowser/getAdData',
      method: 'POST',
      data
    })
  },
  // http://points-mall-dev.henshihui.com/index/nft/get-nft-detail-by-token-id
  getNFTDetailById(params) {
    return request({
      url: `${config.mallUrl}/index/nft/get-nft-detail-by-token-id`,
      method: 'GET',
      params
    })
  },
  getNFTDetailBySymbol(params) {
    return request({
      url: `${config.mallUrl}/index/nft/get-nft-detail-by-symbol`,
      method: 'GET',
      params
    })
  }
}
