import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhTWLocale from 'element-ui/lib/locale/lang/zh-TW'
import customerEN from './en'
import customerCh from './zh-CN'
import customerTW from './zh-TW'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'zh-CN',
  messages: {
    en: {
      ...enLocale,
      ...customerEN
    },
    'zh-CN': {
      ...zhLocale,
      ...customerCh
    },
    'zh-TW': {
      ...zhTWLocale,
      ...customerTW
    }
  }
})

export default i18n
