import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import i18n from '@/lang'
import 'reset-css'
import '@/components/element-ui'
import layout from '@/components/layout'
import Element from 'element-ui'
import {
  formatNumber,
  formatTime
} from '@/utils/index'
Vue.config.productionTip = false
// 注册格式化数字的filter
Vue.filter('add_comma_toThousands', (value) => {
  if (value) {
    return formatNumber(value)
  };

  return value
})
Vue.component('layout', layout)
Vue.filter('formatTime', value => {
  if (value) {
    return formatTime(value)
  }
  return value
})
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})
// emptyDefaultVal: '--', // 当结果为空时，默认显示的值
Vue.filter('empty_default_val', (value) => {
  if (value === '' || (!value && typeof value === 'object')) {
    return '--'
  };

  return value
})
new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
