<template>
  <div class="layout" v-loading="isLoading">
    <!-- 头部 -->
    <slot name="home-header">
      <headerWrap ref="home" :isSearch="isSearch"></headerWrap>
    </slot>
    <!-- 内容区域 -->
    <div :class="{'home-main':!$slots['home-header'],'search-main':isSearch}" >
      <slot name="breadcrumb-nav" v-if="breadList">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb-nav">
          <el-breadcrumb-item @click.native="handleClick" :to="item.path ? { path: item.path } : ''" v-for="(item, index) in breadList" :key="item.path + item.name + index">{{item.name}}</el-breadcrumb-item>
        </el-breadcrumb>
      </slot>
      <slot name="page-title" v-if="pageTitle">
        <h3 class="fc3 title">{{ pageTitle }}</h3>
      </slot>
      <div class="main-inner">
        <slot></slot>
      </div>
      <!-- <slot name="page-paging" v-if="isShowPage">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="forms.page" :page-sizes="[10, 20, 50]" :page-size="forms.limit" layout="total, sizes, prev, pager, next, jumper" :total="totals"></el-pagination>
      </slot> -->
      <!-- <div class="footer">
        <slot name="footer"></slot>
      </div>-->
    </div>
  </div>
</template>

<script>
import headerWrap from './common/headerWrap.vue'
import {
  mapMutations
} from 'vuex'
// import ChainHeader from '@/components/ChainHeader'
// import pageMixins from '@/utils/pageMixins'
// import searchMixins from '@/utils/searchMixins'

export default {
  props: {
    isSearch: {
      type: Boolean,
      default: false
    }
  },
  // mixins: [pageMixins, searchMixins],
  components: {
    // ChainHeader
    headerWrap
  },
  name: 'layout',
  data() {
    return {
      breadList: [],
      noPageList: ['integralBrowser', 'leaguePoints', 'tradingInfo', 'address', 'empty']
    }
  },
  computed: {
    breadLists() {
      return this.$route.meta.breadList
    },
    pageTitle() {
      return this.$route.meta.pageTitle
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    totals() {
      return this.$store.state.totals
    },
    isShowPage() {
      /**
       * noPageList: 不显示分页列表
       *
       */
      return this.noPageList.includes(this.$route.name)
        ? false
        : this.totals > this.forms.limit
    }
  },
  watch: {
    breadLists: {
      handler(val) {
        this.breadList = val
      },
      immediate: true,
      deep: true
    }
  },
  created() {},
  methods: {
    ...mapMutations('homeWrap', [
      'setQuery'
    ]),
    handleClick() {
      this.setQuery({
        page: 1,
        size: 10,
        condition: ''
      })
    }
  }
}
</script>

<style scoped lang="less">
.home-main{
  margin:80px auto 0;
  width: 1200px;
}
.breadcrumb-nav{
  padding: 16px 0 8px;
  line-height: 20px;
}
// .main-inner{

// }
@media screen and (max-width: 1024px) {
  .home-main{
    margin: 56px auto 24px;
    width: auto;
    max-width: 1024px;
  }
  .breadcrumb-nav{
    margin-left: 16px;
    padding: 8px 0;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #333;
    ::v-deep .el-breadcrumb__inner{
      color: #333;
    }
  }
  .search-main{
    margin: 108px auto 34px;
    .breadcrumb-nav{
      display: none;
    }
  }

}
</style>
