import axios from 'axios'
import {
  Message
} from 'element-ui'
import urls from '@/config/urls'

import {
  sha256
} from 'js-sha256'

let dateStamp = new Date().getTime(),
  str = '713SW6OTYRUN6RV0PB15HYXAQCWT4G8Y',
  backsign = sha256(str + dateStamp)
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
    baseURL: urls.usdt, // 默认url
    timeout: 4000, // 请求超时时间,
    withCredentials: true
  }),
  _csrf = document.getElementsByTagName('meta')['csrf-token'] ? document.getElementsByTagName('meta')['csrf-token'].getAttribute('content') : ''

// 请求拦截
service.interceptors.request.use(
  function(config) {
    let params = {
      ...config.params,
      backsign: backsign,
      nonce_str: dateStamp
    }
    if (config.method.toUpperCase() === 'POST') {
      if (config.data) {
        config.data.backsign = backsign
        config.data.nonce_str = dateStamp
      } else {
        config.data = {
          backsign: backsign,
          nonce_str: dateStamp
        }
      }
    }

    // post请求添加csrf
    if (config.method === 'post') {
      if (params) {
        params._csrf = _csrf
      } else {
        params = {
          _csrf: _csrf
        }
      }
    }

    config.params = params
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

// 返回拦截器
service.interceptors.response.use(
  res => {
    let data = res.data,
      status = data.return_code,
      msg = data.return_msg
    if (res.config.method !== 'options') {
      if (status === 'SUCCESS') {
        // let msgStr = JSON.stringify(msg)
        // msgStr = msgStr.replace(/\"\"/g, '"--"').replace(/null/g, '"--"')
        // console.log(msgStr)
        // return JSON.parse(msgStr)
        return msg
      } else {
        Message({
          type: 'error',
          message: msg
        })
      }

      return Promise.reject(msg)
    }
  },
  error => {
    Message({
      message: error.return_msg,
      type: 'error',
      duration: 2 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
