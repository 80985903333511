import {
  getEnv
} from '@/utils/index'

let usdt, mallUrl

if (/^localhost/.test(location.host)) {
  usdt = 'https://wallet-test.aiduifen.com',
  mallUrl = 'http://points-mall-test.henshihui.com'
} else {
  switch (getEnv()) {
  case 'dev':
    usdt = 'https://wallet-dev.aiduifen.com',
    mallUrl = 'http://points-mall-dev.henshihui.com'
    break
  case 'test':
    usdt = 'https://wallet-test.aiduifen.com',
    mallUrl = 'https://points-mall-test.henshihui.com'
    break
  case 'prod':
    usdt = 'https://wallet.aiduifen.com',
    mallUrl = 'https://points-mall.henshihui.com'
    break

  default:
    usdt = 'https://wallet.aiduifen.com',
    mallUrl = 'https://points-mall.henshihui.com'
    break
  }
}

export default {
  usdt,
  mallUrl
}
